<template>
  <div class="index-container">
    <div class="bread">
      <span @click="home()">操作台 / </span>
      <span class="gray">车辆库</span>
    </div>
      <el-row class="table-body">
        <div class="line clearfix">
          <h5 class="fl title">车辆库</h5>
          <div class="fr search">
            <el-input size="small" type="text" placeholder="请输入车牌号" v-model="keywords">
              <template slot="prefix">
                <el-button icon="el-icon-search" type="text" disabled></el-button>
              </template>

            </el-input>
            <el-button type="primary" size="small" @click="getList">查询</el-button>
          </div>
        </div>
        <div class="list">
          <el-table
              :data="tableData"
              style="width: 100%">
            <el-table-column
                fixed
                type="index"
                label="序号"
                width="80">
            </el-table-column>
            <el-table-column
                prop="carNum"
                label="车牌号"
                width="300">
            </el-table-column>
            <el-table-column
                prop="carBrand"
                label="品牌型号"
                min-width="120">
              <template slot-scope="scope">
                {{scope.row.carBrand}}{{scope.row.carMode}}
              </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="120">
              <template slot-scope="scope">
                <el-button
                    type="text"
                    size="small"
                    @click="handleCatCar(scope.row)"
                >
                  查看
                </el-button>
                <el-button
                    type="text"
                    size="small"
                @click="handleDelCar(scope.row)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pages">
          <el-pagination
              background
              layout="prev, pager, next"
              @current-change="handleCurrentChange"
              :hide-on-single-page="showPage"
              :page-size="pageSize"
              :total="total">
          </el-pagination>
        </div>
      </el-row>
    <edit-shelves ref="shelves" v-on:getList="getList" ></edit-shelves>
    <car-house ref="catCar"></car-house>
  </div>
</template>

<script>
import EditShelves from "@/components/EditShelves";
import CarHouse from "@/components/CarHouse";
import {downUp, getList} from "@/utils/api/car";
import {delOne} from "@/utils/api/car";

export default {
  name: "zbcz",
  components:{
    EditShelves,
    CarHouse
  },
  data(){
    return{
      total:11, //总条数
      pageNo:1,
      pageSize:10,
      showPage:true,
      tableData: [
          // {
          //   id:1,
          //   carNum:"沪AG6666",
          //   carBrand:"车辆品牌",
          //   carModel:"车辆型号",
          //   operPlatform:'', //运行平台，
          //   contact:"",//联系人
          //   phone:"",//联系电话
          //   km:"",//公里数
          //   rent:"",//租金
          //   deposit:""//押金
          // },
        ], //表格数据
      keywords:"",//搜索关键字
      editOne:'', //编辑数据
    }
  },
  created() {
    this.getList()
  },
  methods:{
    home(){
      this.goHome()
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getList()
    },
    toShelves(row){
      this.$refs['shelves'].showEdit(row)
    },
    handleDelCar(row){
      this.$confirm('此操作将删除该车辆, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          // type: "1",
          id: row.id
        }
        let that = this
        delOne(data).then(res => {
          if(res.code == 0){
            that.$baseMessage('删除成功','success')
            setTimeout(()=>{
              that.pageNo=1
              that.getList()
            }, 100)
          }else{
            that.$baseMessage(res.msg,'error')
          }
        })
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });
      });
    },
    handleCatCar(value){

      this.$refs['catCar'].showCar(value)
    },
    getList(){
      let data={
        type : "-1",
        rows: this.pageSize,
        // totalPage:this.pageNo,
        page: this.pageNo,
      }
      if (this.keywords !== ""){
        data.carNum=this.keywords
      }
      getList(data).then(res=>{
        if(res.code === 0){
          this.tableData = res.data.list
          this.total = Number(res.data.total)
        }
      })
    }
  }
}
</script>

<style  lang="less">
.table-body{
  background-color: #FFFFFF;
  padding:30px ;
  padding-top: 0;
  .line{
    height: 90px;
    line-height: 90px;
    .title{
      font-size: 16px;
      font-weight: 800;
      color: #333333;
    }
    .search{
      display: flex;
      align-items: center;
      align-content: center;
      justify-items: flex-end;

      .el-input{
        font-size: 12px;
        margin-right: 12px;

        .el-input__inner{
          border: none;
          background-color: #FAFAFA;
          padding-left: 50px;
        }
        .el-input__prefix{
          width: 40px;
          &:after{
            content: '';
            width: 1px;
            height: 15px;
            background-color: #D8D7D7;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

    }
  }
}
</style>
